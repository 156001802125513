const baseAddress = "http://localhost:8000";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const columns = [
  {
    field: "text",
    label: "Text",
    headerName: "text",
  },
  {
    field: "covid_stats",
    label: "Covid Stats",
    borderColor: "#3cd88d",
    areaColor: "#3cd88d",
    backgroundColor: "#f77189",
    headerName: "covid \n stats",
    description:
      " Statistics about new cases, deaths, total cases, tests, casualties, etc.",
  },
  {
    field: "vaccination",
    label: "Vaccination",
    borderColor: "#00f7d6",
    areaColor: "#00f7d6",
    backgroundColor: "#d58c32",
    headerName: "vaccination",
    description: "Serious information regarding vaccination.",
  },
  {
    field: "covid_politics",
    label: "Covid Politics",
    areaColor: "#ede357",
    borderColor: "#ede357",
    backgroundColor: "#a4a031",
    headerName: "covid \n politics",
    description:
      "National and International politics closely concerned with Covid.",
  },
  {
    field: "humour",
    label: "Humour",
    borderColor: "#6fe149",
    areaColor: "#6fe149",
    backgroundColor: "#50b131",
    headerName: "humour",
    description: "Satire, humour related to Covid.",
  },
  {
    field: "lockdown",
    label: "Lockdown",
    borderColor: "#c11364",
    backgroundColor: "#c11364",
    areaColor: "#c11364",
    headerName: "lockdown",
    description:
      "Information regarding lockdown, nisedhagyas, exams during lockdown.",
  },
  {
    field: "civic_views",
    label: "Civic Views",
    borderColor: "#83a7d3",
    backgroundColor: "#83a7d3",
    areaColor: "#83a7d3",
    headerName: "civic \n views",
    description: "General views, suggestions, rants from the public.",
  },
  {
    field: "life_during_pandemic",
    label: "Life During Pandemic",
    borderColor: "#fc3326",
    areaColor: "#fc3326",
    backgroundColor: "#3ba3ec",
    headerName: "Life During \nPandemic",
    description: "Effect of covid on day-to-day activities of the public.",
  },
  {
    field: "covid_waves_and_variants",
    borderColor: "#ec74f2",
    backgroundColor: "#ec74f2",
    areaColor: "#ec74f2",
    label: "Covid Waves and Variants",
    headerName: "Covid Waves \n and \n Variants",
    description: "Different waves and variants of the virus.",
  },
  {
    field: "others",
    borderColor: "#19d1c4",
    backgroundColor: "#19d1c4",
    label: "Others",
    areaColor: "#19d1c4",
    headerName: "Others",
    description: "Not related to any Covid Categories,",
  },
  { field: "verify", label: "Verify", headerName: "Verify" },
];
export { baseAddress, columns, months };
